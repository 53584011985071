import dayjs from "dayjs";

const secondsToDate = (value?: number | string) => {
	const currentTime = new Date();
	const time = currentTime.setSeconds(currentTime.getSeconds() + Number(value));

	return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
};

export default secondsToDate;
